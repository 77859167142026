import * as React from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { colors } from "../style";

const styles = StyleSheet.create({
  h1: {
    fontSize: 38,
    lineHeight: 1.2,
    padding: "4px 0px",
    "@media (max-width: 600px)": {
      fontSize: 42
    }
  },
  h2: {
    fontSize: 24,
    lineHeight: "32px",
    padding: "4px 0px",
    fontWeight: 600
  }
});

const defaultProps = {
  color: colors.darkBlue
};

export interface H1Props {
  color?: string;
  style?: React.CSSProperties;
}

export const H2: React.StatelessComponent<H1Props> = props => {
  return (
    <h2
      className={css(styles.h2)}
      style={{ ...props.style, color: props.color || defaultProps.color }}
    >
      {props.children}
    </h2>
  );
};

export const H1: React.StatelessComponent<H1Props> = props => {
  return (
    <h1
      className={css(styles.h1)}
      style={{ ...props.style, color: props.color || defaultProps.color }}
    >
      {props.children}
    </h1>
  );
};
