import * as React from "react";
import { connect } from "react-redux";
import { State } from "../reducers";
import { getUserTokenExpiryDate } from "../selectors/user";
import moment from "moment";
import { logoutUser } from "../actions/user";
import { ToastContainer } from "react-toastify";

export interface Props {
  expiry?: number;
  logoutUser: typeof logoutUser;
}

class Root extends React.Component<Props> {
  public componentWillMount() {
    if (
      this.props.expiry &&
      moment(this.props.expiry).isSameOrBefore(moment())
    ) {
      this.props.logoutUser();
    }
  }

  public render() {
    return (
      <React.Fragment>
        <ToastContainer />
        {this.props.children}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: State) => ({
  expiry: getUserTokenExpiryDate(state)
});

const mapDispatchToProps = {
  logoutUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Root);
