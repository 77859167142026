import { State } from "../reducers";
import { createSelector } from "reselect";
import { getBoundsFilter, getQueryFilter, getSortFilter } from "./filters";
import { Monument } from "../reducers/monuments";
import { getHighlights } from "./highlights";
import moment from "moment";

export const getMonuments = (state: State) => state.monuments;

export const getMonumentById = (state: State, id: number) =>
  getMonuments(state)[id];

export const isMonumentDetailView = (state: State) =>
  state.router.location.pathname.includes("/detail");

export const getMonumentLocationFromDetails = (state: State) => {
  const { location } = state.router;

  if (!location || !location.pathname.includes("/detail")) {
    return undefined;
  }

  const monumentId = parseInt(location.pathname.split("/")[3]);
  const monument = getMonumentById(state, monumentId);

  if (!monument) {
    return undefined;
  }

  return [monument.longitude, monument.latitude] as [number, number];
};

const comparatorPerSortKey = {
  inscribed_date: (a: Monument, b: Monument) => {
    return moment(b.inscribed_date).unix() - moment(a.inscribed_date).unix();
  },
  site_name: (a: Monument, b: Monument) => {
    return a.site_name < b.site_name ? -1 : 1;
  },
  states: (a: Monument, b: Monument) => {
    return a.states < b.states ? -1 : 1;
  },
};

export const getMonumentsFromBounds = createSelector(
  getMonuments,
  getBoundsFilter,
  getSortFilter,
  (monuments, bounds, sortKey) => {
    if (!Object.keys(monuments).length) {
      return undefined;
    }

    const monumentsFromBounds = bounds.length
      ? Object.values(monuments).filter((monument) => {
          const lat = monument.latitude;
          const long = monument.longitude;

          return (
            lat > bounds[0] &&
            long > bounds[1] &&
            lat < bounds[2] &&
            long < bounds[3]
          );
        })
      : Object.values(monuments);

    const sortedMonuments = monumentsFromBounds.sort(
      comparatorPerSortKey[sortKey]
    );

    return sortedMonuments;
  }
);

export const getMonumentFromQuery = createSelector(
  getMonuments,
  getQueryFilter,
  (monuments, query) =>
    query && Object.keys(monuments).length
      ? Object.values(monuments).filter(
          (monument) =>
            !!monument.site_name &&
            monument.site_name.toLowerCase().includes(query.toLowerCase())
        )
      : undefined
);

export const getHighlightedMonuments = createSelector(
  getMonuments,
  getHighlights,
  (monuments, highlights) => {
    if (!Object.keys(monuments).length || !Object.keys(highlights).length) {
      return undefined;
    }

    return Object.values(highlights)
      .sort((a, b) => (a.ranking_index > b.ranking_index ? 1 : -1))
      .map((h) => monuments[h.monument_id])
      .filter(Boolean);
  }
);

export const getFilteredMonuments = createSelector(
  getMonumentsFromBounds,
  getMonumentFromQuery,
  (monumentsFromBounds, monumentsQuery) => {
    if (monumentsQuery) {
      return monumentsQuery;
    }

    if (!monumentsFromBounds) {
      return [];
    }

    return monumentsFromBounds;
  }
);
