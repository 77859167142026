import { Action } from "redux";
import { SET_REVIEWS } from "../constants/review";

export interface Review {
  id: string;
  monument_id: number;
  user_email: string;
  star?: boolean;
  rate?: number;
  comment?: string;
  created_at: string;
  updated_at?: string;
}

export interface ReviewState {
  [key: string]: Review;
}

interface ActionWithPayload extends Action {
  payload: any;
}

const initialReviewState = {};

export const reviewsReducer = (
  state: ReviewState = initialReviewState,
  action: ActionWithPayload
) => {
  switch (action.type) {
    case SET_REVIEWS: {
      return {
        ...state,
        ...action.payload.reduce((acc, next) => {
          acc[next.id] = next;
          return acc;
        }, {})
      };
    }
    default: {
      return state;
    }
  }
};
