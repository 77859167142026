import {
  SET_USER_POSITION,
  SET_GOOGLE_USER,
  LOGOUT_USER,
  SET_MONUMAP_USER
} from "../constants/user";
import { req, api } from "../fetch";
import { Dispatch } from "redux";

export const setUserPosition = (pos: any) => ({
  type: SET_USER_POSITION,
  payload: pos
});

export interface GoogleUser {
  tokenId: string;
  accessToken: string;
  profileObj: {
    googleId: string;
    imageUrl: string;
    email: string;
    name: string;
    givenName: string;
    familyName: string;
  };
  tokenObj: {
    expires_at: number;
  };
}

interface MonumapUser {
  id: string;
  user_email: string;
  user_permissions?: string;
  created_at?: string;
  updated_at?: string;
}

export const getMonumapUser = (email: string, accessToken: string) => (
  dispatch: Dispatch
) =>
  fetch(
    req(api(`api-dev-get-user?userEmail=${email}`), "GET", undefined, {
      authorization: accessToken
    })
  )
    .then(res => res.json())
    .then(payload => dispatch(setMonumapUser(payload.data)));

export const setMonumapUser = (user: MonumapUser) => ({
  type: SET_MONUMAP_USER,
  payload: user
});

export const setGoogleUser = (user: GoogleUser) => ({
  type: SET_GOOGLE_USER,
  payload: user
});

export const logoutUser = () => ({
  type: LOGOUT_USER
});
