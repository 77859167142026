import { SET_ADDITIONALS } from "../constants/additionals";
import { req, api } from "../fetch";
import { toast } from "react-toastify";

export const setAdditionals = (payload: any) => ({
  type: SET_ADDITIONALS,
  payload,
});

export const getAdditionals = (monumentId?: number) => (dispatch: any) => {
  const endpoint = monumentId
    ? `api-dev-additionals?monumentId=${monumentId}`
    : "api-dev-additionals";

  return fetch(req(api(endpoint)))
    .then((res) => res.json())
    .then((payload) => dispatch(setAdditionals(payload.data)));
};

export interface AdditionalRequest {
  monument_id: number;
  additional_informations: string;
  ticket_url: string;
  title: string;
  rating: string;
  affiliate_name: string;
}

export const postAdditionals = (
  additionals: AdditionalRequest[],
  token: string
) => () => {
  return fetch(
    req(api("api-dev-additionals"), "POST", additionals, {
      authorization: token,
    })
  )
    .then((res) => res.json())
    .then((payload) => {
      toast.info(payload.data, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    });
};
