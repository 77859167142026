import { SET_HIGHLIGHTS } from "../constants/highlights";
import { req, api } from "../fetch";
import { toast } from "react-toastify";
import { fetchMonument } from "./monument";

export const setHighLights = (payload: any) => ({
  type: SET_HIGHLIGHTS,
  payload,
});

export const deleteHighlight = (monumentId: number, token: string) => (
  dispatch: any
) =>
  fetch(
    req(
      api(`api-dev-delete-highlights?category=all&monumentId=${monumentId}`),
      "DELETE",
      {},
      {
        authorization: token,
      }
    )
  ).then(() => dispatch(getHighLights()));

export const postAllHighlights = (
  monumentId: number,
  ranking: number,
  token: string
) => (dispatch: any) =>
  fetch(
    req(
      api("api-dev-post-highlights"),
      "POST",
      {
        monument_id: monumentId,
        ranking_index: ranking,
        category: "all",
      },
      {
        authorization: token,
      }
    )
  )
    .then((res) => res.json())
    .then((payload) => dispatch(setHighLights(payload.data)));

export const getHighLights = () => (dispatch: any) =>
  fetch(req(api(`api-dev-get-highlights?category=all&limit=999`)))
    .then((res) => res.json())
    .then((payload) => dispatch(setHighLights(payload.data)))
    .catch(() =>
      toast.error("Failed to get highlights", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      })
    );

export const getHighlightAndMonument = () => (dispatch: any) => {
  fetch(req(api(`api-dev-get-highlights?category=all&limit=20`)))
    .then((res) => res.json())
    .then((payload) => {
      const monumentIds = payload.data
        .map((highlight) => highlight.monument_id)
        .join(",");

      dispatch(setHighLights(payload.data));
      dispatch(fetchMonument(monumentIds));
    });
};
