import { State } from "../reducers";
import { createSelector } from "reselect";
import {
  getMonumentLocationFromDetails,
  isMonumentDetailView
} from "./monuments";

export const getUserState = (state: State) => {
  return state.user;
};

export const getUserEmail = createSelector(
  getUserState,
  user => user.email
);

export const getUserTokenExpiryDate = createSelector(
  getUserState,
  user => user.expires_at
);

export const getUserPosition = createSelector(
  getUserState,
  user => (user.latitude ? [user.longitude, user.latitude] : undefined)
);

const defaultLocation = [-0.2416815, 51.5285582];

export const getMapLastLocation = () => {
  const position = localStorage.getItem("center");

  return position ? JSON.parse(position) : undefined;
};

export const getUserPositionWithDefault = createSelector(
  getUserPosition,
  getMonumentLocationFromDetails,
  isMonumentDetailView,
  getMapLastLocation,
  (userPosition, monumentLocation, isMonumentSelected, mapLastPosition) => {
    if (isMonumentSelected) {
      return monumentLocation;
    }

    if (mapLastPosition) {
      return mapLastPosition;
    }

    return userPosition || defaultLocation;
  }
);

const getUserLocalStorageZoom = createSelector(
  () => {
    const zoom = localStorage.getItem("zoom");
    return zoom ? parseFloat(zoom) : undefined;
  },
  zoom => [zoom]
);

const defaultZoom: [number] = [6];

export const getUserLastZoom = createSelector(
  getUserLocalStorageZoom,
  userZoom => (userZoom || defaultZoom) as [number]
);

export const getUserPermission = createSelector(
  getUserState,
  user => user.user_permissions
);

export const getUserAccessToken = createSelector(
  getUserState,
  user => user.accessToken
);

export const isUserLoggedIn = createSelector(
  getUserState,
  user => !!user.accessToken
);
