import { getMonuments } from "./monuments";
import { State } from "../reducers";

export const getHighlights = (state: State) => state.highlights;

export const getHighlightsMonuments = (state: State) => {
  const monuments = getMonuments(state);
  const highlights = getHighlights(state);

  return {
    highlights: Object.values(highlights),
    monuments: Object.values(highlights).map((hl) => monuments[hl.monument_id]),
  };
};

export const getHighlightedMonuments = (state: State) => {
  const monuments = getMonuments(state);
  const highlights = getHighlights(state);

  return Object.values(highlights)
    .sort((a, b) => (a.ranking_index > b.ranking_index ? -1 : 1))
    .map((highlight) => monuments[highlight.monument_id])
    .filter(Boolean);
};
