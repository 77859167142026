import * as React from "react";
import NavContainer from "./nav-abstract";
import { connect } from "react-redux";
import { State } from "../reducers";
import { getUserState, isUserLoggedIn } from "../selectors/user";
import { UserState } from "../reducers/user";
import { StyleSheet, css } from "aphrodite/no-important";
import { colors } from "../style";
import { logoutUser } from "../actions/user";
import { Link } from "react-router-dom";
import GoogleLogin from "react-google-login";
import { setGoogleUser, getMonumapUser } from "../actions/user";
import { toast } from "react-toastify";

export interface HomeNavProps {
  user: UserState;
  pathname: string;
  logoutUser: typeof logoutUser;
  isLoggedIn: boolean;
  setGoogleUser: typeof setGoogleUser;
  getMonumapUser: (email: string, accessToken: string) => any;
}

const userStyles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    width: 300,
  },
  navItem: {
    flex: 1,
    padding: "0px 10px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    fontWeight: 600,
  },
  logout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 600,
    color: colors.darkBlue,
    padding: "0px 10px",
    marginRight: 10,
    cursor: "pointer",
  },
});

interface UserProps {
  user: UserState;
  onLogout: () => any;
  pathname: string;
  isLoggedIn: boolean;
  setGoogleUser: typeof setGoogleUser;
  getMonumapUser: (email: string, accessToken: string) => any;
}

interface UserNavState {
  dropdownOpen: boolean;
}

class UserNav extends React.Component<UserProps, UserNavState> {
  isActive = (path) => {
    return path === this.props.pathname;
  };

  responseGoogle = (payload) => {
    this.props.setGoogleUser(payload);
    this.props.getMonumapUser(payload.profileObj.email, payload.accessToken);
  };

  errorresponseGoogle = () => {
    toast.error(
      "Failed to login to Google, contact us if it persist: contact@monumap.com ",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      }
    );
  };

  renderLoggedInNav = () => {
    return (
      <nav className={css(userStyles.container)}>
        <div className={css(userStyles.navItem)}>
          <Link
            className={css(userStyles.link)}
            style={{
              color: this.isActive("/") ? colors.orange : colors.darkBlue,
            }}
            to="/"
          >
            Home
          </Link>
        </div>
        <div className={css(userStyles.navItem)}>
          <Link
            className={css(userStyles.link)}
            style={{
              color: this.isActive("/app") ? colors.orange : colors.darkBlue,
            }}
            to="/app"
          >
            Explore
          </Link>
        </div>
        <div className={css(userStyles.navItem)}>
          <Link
            className={css(userStyles.link)}
            style={{
              color: this.isActive("/favourites")
                ? colors.orange
                : colors.darkBlue,
            }}
            to="/favourites"
          >
            Saved
          </Link>
        </div>
        <div onClick={this.props.onLogout} className={css(userStyles.logout)}>
          Log Out
        </div>
      </nav>
    );
  };

  renderNav = () => {
    return (
      <nav className={css(userStyles.container)}>
        <div className={css(userStyles.navItem)}>
          <Link
            className={css(userStyles.link)}
            style={{
              color: this.isActive("/") ? colors.orange : colors.darkBlue,
            }}
            to="/"
          >
            Home
          </Link>
        </div>
        <div className={css(userStyles.navItem)}>
          <Link
            className={css(userStyles.link)}
            style={{
              color: this.isActive("/app") ? colors.orange : colors.darkBlue,
            }}
            to="/app"
          >
            Explore
          </Link>
        </div>
        <div style={{ padding: "0px 10px" }}>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            buttonText="Sign in"
            onSuccess={this.responseGoogle}
            onFailure={this.errorresponseGoogle}
            cookiePolicy={"single_host_origin"}
          />
        </div>
      </nav>
    );
  };

  render() {
    const { isLoggedIn } = this.props;

    return !!isLoggedIn ? this.renderLoggedInNav() : this.renderNav();
  }
}

class Homenav extends React.Component<HomeNavProps> {
  onLogout = () => {
    this.props.logoutUser();
  };

  public render() {
    const {
      user,
      isLoggedIn,
      pathname,
      setGoogleUser,
      getMonumapUser,
    } = this.props;
    return (
      <NavContainer>
        <UserNav
          setGoogleUser={setGoogleUser}
          getMonumapUser={getMonumapUser}
          user={user}
          isLoggedIn={isLoggedIn}
          onLogout={this.onLogout}
          pathname={pathname}
        />
      </NavContainer>
    );
  }
}

const mapStateToProps = (state: State) => ({
  user: getUserState(state),
  isLoggedIn: isUserLoggedIn(state),
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = {
  logoutUser,
  setGoogleUser,
  getMonumapUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Homenav);
