import * as React from "react";
import { connect } from "react-redux";
import { State as StateRoot } from "../reducers/index";
import { StyleSheet, css } from "aphrodite/no-important";
import Slider from "./slider";
import { colors } from "../style";
import Back from "../icons/back";
import Fullscreen from "./fullscreen";
import { goBack, push } from "connected-react-router";
import { fetchMonument } from "../actions/monument";
import { Monument } from "../reducers/monuments";
import { H2 } from "./title";
import ActionBar from "./detail-action-bar";
import {
  isUserLoggedIn,
  getUserEmail,
  getUserAccessToken,
} from "../selectors/user";
import {
  fetchReview,
  PostReviewPayload,
  postReview,
  GetReviewParams,
} from "../actions/review";
import { getReviewForCurrentUser } from "../selectors/review";
import { Review } from "../reducers/reviews";
import { toast } from "react-toastify";

interface OwnProps {
  params: {
    id: string;
  };
}

export interface Props extends OwnProps {
  monument: Monument;
  goBack: typeof goBack;
  push: typeof push;
  fetchMonument: (id: number) => any;
  fetchReview: (params: GetReviewParams) => any;
  postReview: (payload: PostReviewPayload, token: string) => any;
  isLoggedIn: boolean;
  reviewForCurrentUser?: Review;
  userEmail: string;
  userToken?: string;
}

interface State {
  isFullscreen: boolean;
}

const styles = StyleSheet.create({
  container: {
    width: 520,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxHeight: "calc(100vh - 80px)",
    "@media (max-width: 600px)": {
      width: "100vw",
    },
  },
  monumentDetails: {
    padding: "20px 32px",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  leading: {
    color: colors.darkBlue,
    margin: "10px 0px",
  },
  title: {
    color: colors.darkBlue,
    fontSize: 24,
    lineHeight: "32px",
    padding: "4px 0px",
  },
  region: {
    color: colors.grey,
    fontWeight: 400,
  },
  description: {
    fontSize: 16,
    fontWeight: 300,
    color: colors.darkBlue,
    marginTop: 12,
    lineHeight: "26px",
  },
  contentWrapper: {
    flex: 1,
    overflow: "auto",
    "-webkit-overflow-scrolling": "touch",
  },
  footer: {
    borderBottom: "1px solid #edeaea",
    height: 56,
    minHeight: 56,
    display: "flex",
    alignItems: "center",
  },
  allSites: {
    display: "flex",
    alignItems: "center",
    width: 56,
    height: "100%",
    justifyContent: "center",
    fontWeight: 400,
    cursor: "pointer",
    borderRight: "1px solid #edeaea",
  },
  back: {
    marginRight: 4,
  },
});

class SidepanDetail extends React.Component<Props, State> {
  public state = {
    isFullscreen: false,
  };

  componentWillMount() {
    this.props.fetchMonument(parseInt(this.props.params.id));
    this.props.fetchReview({
      monumentId: parseInt(this.props.params.id),
      userEmail: this.props.userEmail,
    });
  }

  componentWillUpdate(nextProps: Props) {
    const prevId = parseInt(this.props.params.id);
    const nextId = parseInt(nextProps.params.id);

    if (prevId !== nextId) {
      this.props.fetchMonument(nextId);
      this.props.fetchReview({
        monumentId: nextId,
        userEmail: nextProps.userEmail,
      });
    }
  }

  private onGoBack = () => {
    if (document.documentElement.clientWidth < 600) {
      this.props.goBack();
    } else {
      this.props.push("/app");
    }
  };

  private onFullScreen = () => {
    this.setState({
      isFullscreen: true,
    });
  };

  private onDismissFullscreen = () => {
    this.setState({
      isFullscreen: false,
    });
  };

  onToggleFavorite = () => {
    const {
      reviewForCurrentUser,
      postReview,
      params,
      userEmail,
      userToken,
      isLoggedIn,
    } = this.props;

    if (!isLoggedIn) {
      return toast.info("You must be signed in to add to your favourites", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }

    if (reviewForCurrentUser) {
      postReview(
        {
          ...reviewForCurrentUser,
          star: !reviewForCurrentUser.star,
        },
        userToken
      );
    } else {
      postReview(
        {
          monument_id: parseInt(params.id),
          user_email: userEmail,
          star: true,
        },
        userToken
      );
    }
  };

  public render() {
    const { monument, isLoggedIn, reviewForCurrentUser } = this.props;
    const { isFullscreen } = this.state;

    if (!monument) {
      return null;
    }

    const hasPictures = monument.pictures && monument.pictures.length > 0;

    return (
      <div className={css(styles.container)}>
        {isFullscreen && (
          <Fullscreen
            pictures={monument.pictures}
            onDismissFullscreen={this.onDismissFullscreen}
          />
        )}
        <div className={css(styles.footer)}>
          <div className={css(styles.allSites)} onClick={this.onGoBack}>
            <Back className={css(styles.back)} />
          </div>
          <ActionBar
            isLoggedIn={isLoggedIn}
            onToggleFavourite={this.onToggleFavorite}
            reviewForCurrentUser={reviewForCurrentUser}
          />
        </div>
        <div>
          {hasPictures ? (
            <Slider
              pictures={monument.pictures}
              onFullScreen={this.onFullScreen}
            />
          ) : (
            <img
              src="/placeholder.png"
              style={{ width: "100%" }}
              alt="Default site illustration"
            />
          )}
        </div>
        <div className={css(styles.contentWrapper)}>
          <div
            className={css(styles.monumentDetails)}
            style={{ paddingTop: isLoggedIn ? 0 : 20 }}
          >
            <H2 style={{ marginTop: 20 }}>{monument.site_name}</H2>
            <div className={css(styles.leading)}>{monument.states}</div>
            <div className={css(styles.description)}>
              {monument.short_description}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: StateRoot, props: OwnProps) => ({
  monument: state.monuments[props.params.id],
  isLoggedIn: isUserLoggedIn(state),
  userEmail: getUserEmail(state),
  userToken: getUserAccessToken(state),
  reviewForCurrentUser: getReviewForCurrentUser(
    state,
    parseInt(props.params.id)
  ),
});

const mapDispatchToProps = (dispatch: any) => ({
  goBack: () => dispatch(goBack()),
  push: (path: string) => dispatch(push(path)),
  fetchMonument: (id: number) => fetchMonument(String(id))(dispatch),
  fetchReview: ({ monumentId, userEmail }: GetReviewParams) =>
    fetchReview({ monumentId, userEmail })(dispatch),
  postReview: (payload: PostReviewPayload, token: string) =>
    postReview(payload, token)(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidepanDetail);
