import * as React from "react";
import { Monument } from "../../reducers/monuments";
import { EditImage } from "./edit-images";
import { PostPicturesPayload } from "../../actions/monument";
import EditAdditionals from "./edit-additionals";
import { AdditionalRequest } from "../../actions/additionals";

export interface IEditMonumentProps {
  monument: Monument;
  onSubmitPictures: (pictures: PostPicturesPayload[]) => void;
  onSubmitAdditionals: (additionals: AdditionalRequest[]) => void;
}

export class EditMonument extends React.Component<IEditMonumentProps> {
  public render() {
    return (
      <div style={{ display: "flex", flex: 1 }}>
        {this.props.monument.pictures && (
          <EditImage
            onSubmit={this.props.onSubmitPictures}
            pictures={this.props.monument.pictures}
            monumentId={this.props.monument.monument_id}
          />
        )}
        <EditAdditionals
          onSubmitAdditionals={this.props.onSubmitAdditionals}
          monumentId={this.props.monument.monument_id}
        />
      </div>
    );
  }
}
