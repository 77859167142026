import { req, api } from "../fetch";
import { stringify } from "querystring";
import { setMonuments, setPhotos } from "./monument";
import { setReviews } from "./review";

export const getFavourites = (userEmail: string) => (dispatch: any) => {
  const params = stringify({ userEmail });

  return fetch(req(api(`api-dev-get-favourites?${params}`)))
    .then(res => res.json())
    .then(({ data }) => {
      return [
        dispatch(setMonuments(data.monuments)),
        dispatch(setPhotos(data.pictures)),
        dispatch(setReviews(data.reviews))
      ];
    });
};
