import * as React from "react";
import { css, StyleSheet } from "aphrodite/no-important";
import Button from "../button";
import { colors } from "../../style";
import { AdditionalRequest } from "../../actions/additionals";
import { connect } from "react-redux";
import { State } from "../../reducers";
import { getAdditionalsForMonument } from "../../selectors/additionals";
import { Additional } from "../../reducers/additionals";

const styles = StyleSheet.create({
  input: {
    width: "calc(100% - 80px)",
    margin: "40px 20px",
    padding: "20px",
    fontSize: 14,
  },
});

interface Affiliate {
  url: string;
  score: number;
  title: string;
}

interface OwnState {
  affiliates: Affiliate[];
  currentAffiliate: Affiliate;
}

interface OwnProps {
  monumentId: number;
  onSubmitAdditionals: (additionals: AdditionalRequest[]) => void;
}

export interface IAdditionalsProps extends OwnProps {
  additionals: Additional[];
}

class EditAdditionals extends React.Component<IAdditionalsProps, OwnState> {
  state = {
    affiliates: [],
    currentAffiliate: {
      url: "",
      score: 0,
      title: "",
    },
  };

  onAddAffiliate = () => {
    this.setState({
      affiliates: [
        ...this.state.affiliates,
        {
          url: this.state.currentAffiliate.url,
          title: this.state.currentAffiliate.title,
          score: this.state.currentAffiliate.score,
        },
      ],
    });

    this.setState({
      currentAffiliate: {
        url: "",
        score: 0,
        title: "",
      },
    });
  };

  onSubmit = () => {
    const additionals = this.state.affiliates.map((a) => ({
      monument_id: this.props.monumentId,
      ticket_url: a.url,
      title: a.title,
      rating: a.score,
      affiliate_name: "getyourguide",
      additional_informations: "",
    }));

    this.props.onSubmitAdditionals(additionals);

    this.setState({
      affiliates: [],
    });
  };

  public render() {
    return (
      <div style={{ display: "flex", flexDirection: "column", marginLeft: 40 }}>
        <h1>Manage affiliate</h1>
        <div>
          <input
            className={css(styles.input)}
            type="text"
            placeholder="Affiliate title"
            onChange={(evt) => {
              this.setState({
                currentAffiliate: {
                  ...this.state.currentAffiliate,
                  title: evt.currentTarget.value,
                },
              });
            }}
            value={this.state.currentAffiliate.title}
          />
          <input
            className={css(styles.input)}
            type="text"
            placeholder="Affiliate URL"
            onChange={(evt) => {
              this.setState({
                currentAffiliate: {
                  ...this.state.currentAffiliate,
                  url: evt.currentTarget.value,
                },
              });
            }}
            value={this.state.currentAffiliate.url}
          />
          <input
            className={css(styles.input)}
            type="number"
            placeholder="Affiliate score"
            onChange={(evt) => {
              this.setState({
                currentAffiliate: {
                  ...this.state.currentAffiliate,
                  score: parseFloat(evt.currentTarget.value),
                },
              });
            }}
            value={this.state.currentAffiliate.score}
          />
          <Button
            style={{
              margin: "40px 20px",
              backgroundColor: colors.darkBlue,
              color: "white",
              border: "none",
            }}
            onClick={this.onAddAffiliate}
          >
            Add
          </Button>
        </div>
        <div>
          <div>
            {this.state.affiliates.map((a) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #aaa",
                  padding: "10px 0px",
                  marginBottom: 20,
                }}
              >
                <div>Title: {a.title}</div>
                <div>URL: {a.url}</div>
                <div>Score: {a.score}</div>
              </div>
            ))}
          </div>
          <Button
            onClick={this.onSubmit}
            style={{
              backgroundColor: colors.darkBlue,
              color: "white",
              border: "none",
              width: "100%",
            }}
          >
            Submit Affiliates
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State, ownProps: OwnProps) => ({
  additionals: getAdditionalsForMonument(state)(ownProps.monumentId),
});

export default connect(mapStateToProps)(EditAdditionals);
