import * as React from "react";
import { css, StyleSheet } from "aphrodite";
import { Picture } from "../../reducers/monuments";
import Button from "../button";
import { PostPicturesPayload } from "../../actions/monument";
import { colors } from "../../style";

interface IEditImageProps {
  monumentId: number;
  pictures: Picture[];
  onSubmit: (pictures: PostPicturesPayload[]) => void;
}

interface State {
  picturesToAdd: { rank: number; url: string }[];
  picturesToRemove: string[];
  urlValue: string;
  rankValue: number;
}

const styles = StyleSheet.create({
  image: {
    width: 300,
    height: 150,
    backgroundSize: "cover",
    margin: 10,
  },
  input: {
    width: "calc(100% - 80px)",
    margin: "40px 20px",
    padding: "20px",
    fontSize: 14,
  },
});

export class EditImage extends React.Component<IEditImageProps, State> {
  state = {
    picturesToAdd: [],
    picturesToRemove: [],
    urlValue: "",
    rankValue: -1,
  };

  onSelectPicture = (pictureId: string) => {
    if (this.state.picturesToRemove.includes(pictureId)) {
      this.setState({
        picturesToRemove: this.state.picturesToRemove.filter(
          (p) => p !== pictureId
        ),
      });
    } else {
      this.setState({
        picturesToRemove: [...this.state.picturesToRemove, pictureId],
      });
    }
  };

  onAddPicture = () => {
    this.setState({
      picturesToAdd: [
        ...this.state.picturesToAdd,
        {
          url: this.state.urlValue,
          rank: this.state.rankValue,
        },
      ],
      urlValue: "",
      rankValue: -1,
    });
  };

  onSubmit = () => {
    const withRemovedPictures = this.props.pictures.filter(
      (picture) => !this.state.picturesToRemove.includes(picture.picture_id)
    );

    const picturesToAdd = this.state.picturesToAdd
      .sort((p1, p2) => (p1.rank > p2.rank ? 1 : -1))
      .map((p) => ({
        monument_id: this.props.monumentId,
        picture_url: p.url,
        approved: 1,
      }));

    const newState = [...picturesToAdd, ...withRemovedPictures];

    this.props.onSubmit(newState);
  };

  public render() {
    return (
      <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <h1 style={{ margin: "20px 0px" }}>Remove images</h1>
        <div style={{ display: "flex", flex: 1, flexWrap: "wrap" }}>
          {this.props.pictures.map((picture) => (
            <div
              className={css(styles.image)}
              onClick={() => {
                this.onSelectPicture(picture.picture_id);
              }}
              style={{
                backgroundImage: `url("${picture.picture_url}")`,
                border: this.state.picturesToRemove.includes(picture.picture_id)
                  ? "1px solid red"
                  : "none",
              }}
            />
          ))}
        </div>

        <h1 style={{ margin: "20px 0px" }}>Add images</h1>
        <div>
          {this.state.picturesToAdd.map((p) => (
            <div>
              {p.url} | {p.rank}
            </div>
          ))}
        </div>
        <div style={{ display: "flex" }}>
          <input
            className={css(styles.input)}
            type="text"
            placeholder="URL of picture to add"
            onChange={(evt) => {
              this.setState({
                urlValue: evt.target.value,
              });
            }}
            value={this.state.urlValue}
          />
          <input
            className={css(styles.input)}
            type="number"
            placeholder="Rank"
            onChange={(evt) => {
              this.setState({
                rankValue: parseInt(evt.target.value),
              });
            }}
            value={this.state.rankValue}
          />
          <Button
            style={{
              margin: "40px 20px",
              backgroundColor: colors.darkBlue,
              color: "white",
              border: "none",
            }}
            onClick={this.onAddPicture}
          >
            Add
          </Button>
        </div>
        <Button
          onClick={this.onSubmit}
          style={{
            backgroundColor: colors.darkBlue,
            color: "white",
            border: "none",
          }}
        >
          Submit Images
        </Button>
      </div>
    );
  }
}
