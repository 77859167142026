import { Action } from "redux";
import {
  SET_USER_POSITION,
  SET_GOOGLE_USER,
  LOGOUT_USER,
  SET_MONUMAP_USER
} from "../constants/user";

interface ActionWithPayload extends Action {
  payload: any;
}

export interface UserState {
  latitude?: number;
  longitude?: number;
  tokenId?: string;
  accessToken?: string;
  googleId?: string;
  imageUrl?: string;
  email?: string;
  name?: string;
  givenName?: string;
  familyName?: string;
  expires_at?: number;

  // User infos from monumap DB
  id?: string;
  user_email?: string;
  user_permissions?: string;
  created_at?: string;
  updated_at?: string;
}

const localItem = localStorage.getItem("session");
const initialState = localItem
  ? JSON.parse(localStorage.getItem("session"))
  : {};

export const userReducer = (
  state: UserState = initialState,
  action: ActionWithPayload
) => {
  switch (action.type) {
    case SET_USER_POSITION: {
      return {
        ...state,
        latitude: action.payload.coords.latitude,
        longitude: action.payload.coords.longitude
      };
    }
    case LOGOUT_USER: {
      localStorage.removeItem("session");

      return {
        latitude: state.latitude,
        longitude: state.longitude
      };
    }

    case SET_GOOGLE_USER: {
      const newUserPayload = {
        ...state,
        tokenId: action.payload.tokenId,
        accessToken: action.payload.accessToken,
        ...action.payload.profileObj,
        expires_at: action.payload.tokenObj.expires_at
      };

      return newUserPayload;
    }

    case SET_MONUMAP_USER: {
      const newUserPayload = {
        ...state,
        ...action.payload
      };

      localStorage.setItem("session", JSON.stringify(newUserPayload));

      return newUserPayload;
    }

    default: {
      return state;
    }
  }
};
