import * as React from "react";
import { MapEvent } from "react-mapbox-gl/lib/map-events";
import { throttle } from "lodash";
import { connect } from "react-redux";
import { getMonuments } from "../actions/monument";
import { State } from "../reducers/index";
import UnescoMap from "../components/map";
import { css, StyleSheet } from "aphrodite/no-important";
import SidepanList from "../components/sidepanList";
import SidepanContainer from "../components/sidepanContainer";
import { Route, RouteComponentProps } from "react-router";
import SidepanDetail from "../components/sidepanDetail";
import { push } from "connected-react-router";
import { getViewportWidth } from "../utils";
import { getUserPositionWithDefault, getUserLastZoom } from "../selectors/user";
import { Map } from "mapbox-gl";
import { setBounds } from "../actions/filters";
import { getFilteredMonuments } from "../selectors/monuments";
import { getHighLights } from "../actions/highlights";
import { getAdditionals } from "../actions/additionals";
import Nav from "../components/nav";
import { Monument } from "../reducers/monuments";

interface Props {
  getMonuments: () => any;
  getHighlights: () => any;
  getAdditionals: () => any;
  monuments: Monument[];
  pushMonument: (route: string) => any;
  location: any;
  center: [number, number];
  zoom: [number];
  setBounds: typeof setBounds;
}

interface StateComp {
  // filteredMonuments: string[];
  hoveredItem?: number;
  // bounds: number[];
  hoveredAnchor: string;
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    height: "100%",
  },
});

class Main extends React.Component<Props, StateComp> {
  public state = {
    hoveredItem: undefined,
    // filteredMonuments: [],
    // bounds: [],
    hoveredAnchor: "top",
  };

  public componentWillMount() {
    this.props.getMonuments();
    this.props.getHighlights();
    this.props.getAdditionals();
  }

  private mapInit: MapEvent = (map: any) => {
    const bounds = map.getBounds();
    const boundsArr = [
      bounds.getSouth(),
      bounds.getWest(),
      bounds.getNorth(),
      bounds.getEast(),
    ];

    this.props.setBounds(boundsArr);
  };

  private BoundsChanged: MapEvent = throttle(
    (map: Map) => {
      const bounds = map.getBounds();
      const limitedBounds = map.unproject([60, 60]);
      const center = map.getCenter();
      const zoom = map.getZoom();

      localStorage.setItem("center", JSON.stringify([center.lng, center.lat]));
      localStorage.setItem("zoom", String(zoom));

      const hDiff = Math.abs(bounds.getNorth() - limitedBounds.lat);
      const vDiff = Math.abs(bounds.getWest() - limitedBounds.lng);

      const boundsArr = [
        bounds.getSouth() + hDiff,
        limitedBounds.lng,
        limitedBounds.lat,
        bounds.getEast() - vDiff,
      ];

      this.props.setBounds(boundsArr);
    },
    500,
    { leading: true }
  );

  private onMouseEnter = (key: number) => {
    this.setState({
      hoveredItem: key,
    });
  };

  private onMouseLeave = () => {
    this.setState({
      hoveredItem: undefined,
    });
  };

  private onMonumentClick = (k: number) => {
    this.props.pushMonument(`/app/detail/${k}`);
  };

  private ListWrapper = () => (
    <SidepanContainer>
      <SidepanList
        key={1}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      />
    </SidepanContainer>
  );

  private DetailsWrapper = (
    routerParams: RouteComponentProps<{ id: string }>
  ) => (
    <SidepanContainer>
      <SidepanDetail key={2} params={routerParams.match.params} />
    </SidepanContainer>
  );

  private GetMap = () => {
    const { monuments, center, zoom } = this.props;
    const { hoveredItem } = this.state;
    return (
      <UnescoMap
        zoom={zoom}
        center={center}
        hoveredItem={hoveredItem}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        monuments={monuments}
        BoundsChanged={this.BoundsChanged}
        mapInit={this.mapInit}
        onMonumentClick={this.onMonumentClick}
      />
    );
  };

  public render() {
    const vw = getViewportWidth();

    return (
      <div>
        <div style={{ padding: "0px 22px" }}>
          <Nav />
        </div>
        <div className={css(styles.container)}>
          <Route exact path="/app" component={this.ListWrapper} />
          <Route path="/app/detail/:id" component={this.DetailsWrapper} />
          <Route path="/app/mobile/map" component={this.GetMap} />

          {vw > 600 ? this.GetMap() : null}
        </div>
      </div>
    );
  }
}

export default connect(
  (state: State) => ({
    monuments: getFilteredMonuments(state),
    center: getUserPositionWithDefault(state),
    zoom: getUserLastZoom(state),
  }),
  (dispatch) => ({
    getMonuments: () => getMonuments()(dispatch),
    getHighlights: () => getHighLights()(dispatch),
    pushMonument: (route: string) => dispatch(push(route)),
    setBounds: (bounds: number[]) => dispatch(setBounds(bounds)),
    getAdditionals: () => getAdditionals()(dispatch),
  })
)(Main);
