import * as React from "react";
import { ReactComponent as StarEmpty } from "./star-empty.svg";
import { ReactComponent as StarFull } from "./star-full.svg";
import { css, StyleSheet } from "aphrodite/no-important";
import { colors } from "../style";
import { Review } from "../reducers/reviews";

const styles = StyleSheet.create({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  favourite: {
    display: "flex",
    flexDirection: "column",
    background: "none",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    padding: "12px 16px",
    cursor: "pointer",
    outline: "none",
    color: colors.mediumBlue,
    ":hover": {
      color: colors.darkBlue,
      backgrounColor: colors.lightGrey
    }
  },
  disabled: {
    opacity: 0.6
  }
});

export interface ActionBarProps {
  onToggleFavourite: React.MouseEventHandler;
  reviewForCurrentUser?: Review;
  isLoggedIn?: boolean;
}

export interface ActionBarState {}

export default class ActionBar extends React.Component<
  ActionBarProps,
  ActionBarState
> {
  isFavorite = () => {
    const { reviewForCurrentUser } = this.props;

    return reviewForCurrentUser && !!reviewForCurrentUser.star;
  };

  public render() {
    return (
      <div className={css(styles.container)}>
        <button
          className={css(
            styles.favourite,
            !this.props.isLoggedIn && styles.disabled
          )}
          onClick={this.props.onToggleFavourite}
        >
          {this.isFavorite() ? <StarFull /> : <StarEmpty />}
          <div
            style={{
              fontWeight: this.isFavorite() ? 700 : 500,
              fontSize: 10,
              marginTop: 4,
              color: colors.mediumBlue
            }}
          >
            Add to favourite
          </div>
        </button>
      </div>
    );
  }
}
