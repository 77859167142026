import { Action } from "redux";
import { SET_TAB, SET_QUERY, SET_BOUNDS, SET_SORT } from "../constants/filters";

export type TabOption = "all" | "highlights";

export const SortOptions = {
  inscribed_date: "Year",
  site_name: "Name",
  states: "Country"
};

export interface FilterState {
  searchQuery: string;
  tab: TabOption;
  bounds: number[];
  sort: keyof typeof SortOptions;
}

interface ActionWithPayload extends Action {
  payload: any;
}

const initialReviewState = {
  searchQuery: "",
  tab: "highlights" as "highlights",
  bounds: [],
  sort: Object.keys(SortOptions)[0] as keyof typeof SortOptions
};

export const filtersReducer = (
  state: FilterState = initialReviewState,
  action: ActionWithPayload
) => {
  switch (action.type) {
    case SET_TAB: {
      return {
        ...state,
        tab: action.payload
      };
    }
    case SET_QUERY: {
      return {
        ...state,
        searchQuery: action.payload
      };
    }

    case SET_BOUNDS: {
      return {
        ...state,
        bounds: action.payload
      };
    }

    case SET_SORT: {
      return {
        ...state,
        sort: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
