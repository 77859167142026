import * as React from "react";
import { css, StyleSheet } from "aphrodite/no-important";
import { colors } from "../style";

const styles = StyleSheet.create({
  button: {},
  minimal: {
    backgroundColor: "white",
    color: colors.mediumBlue,
    borderRadius: 2,
    padding: "11px 14px",
    fontSize: 13,
    fontWeight: 500,
    cursor: "pointer",
    border: "1px solid white",
    outline: "none",
    boxShadow:
      "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
    ":hover": {
      opacity: 0.8,
    },
  },
});

export interface ButtonProps {
  onClick: React.MouseEventHandler;
  style?: React.CSSProperties;
  className?: string;
}

export default class Button extends React.Component<ButtonProps> {
  public render() {
    const { children, onClick, style, className } = this.props;
    return (
      <button
        style={style}
        className={`${css(styles.button, styles.minimal)} ${className || ""}`}
        onClick={onClick}
      >
        {children}
      </button>
    );
  }
}
