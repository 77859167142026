import * as React from "react";
import { css, StyleSheet } from "aphrodite";
import Homenav from "../../components/nav";
import { connect } from "react-redux";
import { State } from "../../reducers";
import { getUserPermission, getUserAccessToken } from "../../selectors/user";
import { getMonuments as getMonumentsSelector } from "../../selectors/monuments";
import { push } from "react-router-redux";
import { MonumentDict } from "../../reducers/monuments";
import MonumentItem from "../../components/monumentItem";
import {
  getMonuments,
  fetchMonument,
  PostPicturesPayload,
} from "../../actions/monument";
import { EditMonument } from "../../components/admin/edit-monument";
import { postPictures } from "../../actions/monument";
import {
  postAdditionals,
  AdditionalRequest,
  getAdditionals,
} from "../../actions/additionals";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    "@media (max-width: 600px)": {
      flexDirection: "column-reverse",
    },
  },
  input: {
    width: "calc(100% - 80px)",
    margin: "40px 20px",
    padding: "20px",
    fontSize: 14,
  },
  childContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 20px",
  },
});

export interface ManageMonumentCProps {
  userPermission?: string;
  push: typeof push;
  userToken: string;
  monuments: MonumentDict;
  getMonuments: () => void;
  fetchMonument: (ids: string) => any;
  postPictures: (pictures: PostPicturesPayload[], userToken: string) => any;
  postAdditionals: (additionals: AdditionalRequest[], userToken: string) => any;
  getAdditionals: (monumentId: number) => any;
}

interface CompState {
  query: string;
  selected: number;
}

class ManageMonumentC extends React.Component<ManageMonumentCProps, CompState> {
  state = {
    query: "",
    selected: undefined,
  };

  componentWillMount() {
    this.props.getMonuments();

    if (this.props.userPermission !== "admin") {
      this.props.push("/");
    }
  }

  onInputChange = (evt) => {
    if (evt.target) {
      this.setState({ query: evt.currentTarget.value });
    }
  };

  getFullSelectedMonument = () => {
    const selectedMonument = this.state.selected
      ? this.props.monuments[this.state.selected]
      : undefined;

    return selectedMonument && Array.isArray(selectedMonument.pictures)
      ? selectedMonument
      : undefined;
  };

  onSubmit = (pictures: PostPicturesPayload[]) => {
    this.props.postPictures(pictures, this.props.userToken);
  };

  getFilteredMonument = () => {
    const selectedMonument = this.getFullSelectedMonument();

    if (selectedMonument) {
      return [selectedMonument];
    }

    return Object.values(this.props.monuments).filter(
      (m) =>
        !!this.state.query &&
        m.site_name.toLowerCase().includes(this.state.query.toLowerCase())
    );
  };

  setSelectedMonument = (monumentId: number) => {
    this.setState({
      selected: monumentId,
    });

    this.props.fetchMonument(String(monumentId));

    this.props.getAdditionals(monumentId);
  };

  public render() {
    const selectedMonument = this.getFullSelectedMonument();

    return (
      <div>
        <Homenav />
        <div className={css(styles.container)}>
          <div style={{ flex: 1 }}>
            <input
              className={css(styles.input)}
              type="text"
              placeholder="Search for monument to update"
              onChange={this.onInputChange}
            />
            <div>
              {this.getFilteredMonument().map((m, i) => (
                <div key={i} className={css(styles.childContainer)}>
                  <MonumentItem
                    key={m.monument_id}
                    monument={m}
                    style={{ flex: 1 }}
                    onClick={() => this.setSelectedMonument(m.monument_id)}
                  />
                  <div style={{ display: "flex" }}>
                    {selectedMonument && (
                      <EditMonument
                        monument={selectedMonument}
                        onSubmitPictures={(pictures) => this.onSubmit(pictures)}
                        onSubmitAdditionals={(additionals) => {
                          this.props.postAdditionals(
                            additionals,
                            this.props.userToken
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  push,
  getMonuments,
  fetchMonument,
  postPictures,
  postAdditionals,
  getAdditionals,
};

const mapStateToProps = (state: State) => ({
  userPermission: getUserPermission(state),
  userToken: getUserAccessToken(state),
  monuments: getMonumentsSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageMonumentC);
