import * as React from "react";
import { Layer, Feature } from "react-mapbox-gl";

export interface MonumentLayout {
  "icon-image": "monument" | "nature";
}

interface MonumentLayer {
  monument_id: number;
  latlng: number[];
}

export interface Props {
  monuments: MonumentLayer[];
  onMonumentClick: Function;
  markerHover: Function;
  markerEndHover: Function;
  layout: MonumentLayout;
}

const monumentLayer: React.StatelessComponent<Props> = ({
  monuments,
  onMonumentClick,
  markerHover,
  markerEndHover,
  layout
}) => (
  <Layer type="symbol" id={layout["icon-image"]} layout={layout}>
    {monuments.map(monument => (
      <Feature
        onMouseEnter={markerHover.bind(null, monument.monument_id)}
        onMouseLeave={markerEndHover.bind(null, monument.monument_id)}
        onClick={onMonumentClick.bind(null, monument.monument_id)}
        coordinates={monument.latlng}
        key={monument.monument_id}
      />
    ))}
  </Layer>
);

export default monumentLayer;
