import * as React from "react";
import { css, StyleSheet } from "aphrodite/no-important";
import Search from "./search";
import { colors } from "../style";
import { getViewportWidth } from "../utils";
import { ReactComponent as MapIcon } from "./map.svg";
import { ReactComponent as ListIcon } from "./list.svg";
import NavContainer from "./nav-abstract";
import { Link } from "react-router-dom";

const styles = StyleSheet.create({
  label: {
    fontWeight: 400,
    color: colors.darkBlue,
  },
  container: {
    backgroundColor: "white",
    display: "flex",
    height: 62,
    minHeight: 62,
    justifyContent: "space-between",
    borderTop: "1px solid #edeaea",
  },
  selectContainer: {
    width: 112,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 600px)": {
      width: 60,
    },
  },
  select: {
    flex: 1,
    border: "none",
    background: "none",
    outline: "none",
    color: colors.darkBlue,
    fontSize: 12,
    maxWidth: 55,
  },
  tabs: {
    display: "flex",
    flex: 1,
    justifyContent: "space-around",
  },
  tabItem: {
    color: colors.mediumBlue,
    fontSize: 14,
    cursor: "pointer",
  },
  selectedTab: {
    fontWeight: "bold",
  },
});

export interface Props {
  onSearch?: React.ReactEventHandler<HTMLElement>;
  onSelect?: React.ReactEventHandler<HTMLElement>;
  select?: string[];
  view?: boolean;
  searchValue?: string;
}

export default class Navigation extends React.Component<Props> {
  public render() {
    const { onSearch, onSelect, select, view, searchValue } = this.props;
    const vw = getViewportWidth();

    return (
      <div className={css(styles.container)}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          {onSearch && (
            <Search onChange={onSearch} searchValue={searchValue!} />
          )}

          <div className={css(styles.selectContainer)}>
            {vw > 600 && onSelect && select ? (
              <React.Fragment>
                <div className={css(styles.label)}>Sort:</div>
                <select className={css(styles.select)} onChange={onSelect}>
                  {select.map((val) => (
                    <option key={val}>{val}</option>
                  ))}
                </select>
              </React.Fragment>
            ) : (
              <Link to={view ? "/app/mobile/map" : "/app"}>
                {view ? (
                  <MapIcon style={{ cursor: "pointer" }} />
                ) : (
                  <ListIcon style={{ cursor: "pointer" }} />
                )}
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}
