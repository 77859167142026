import * as React from "react";
import { css, StyleSheet } from "aphrodite/no-important";
import SearchIcon from "../icons/search";
import { colors } from "../style";

export interface Props {
  onChange: any; //tslint:disable-line
  searchValue: string;
}

export interface State {
  search: boolean;
}

const styles = StyleSheet.create({
  input: {
    width: "100%",
    height: "100%",
    border: "none",
    fontSize: 12,
    paddingLeft: 42,
    boxSizing: "border-box",
    backgroundColor: "#eee",
    borderRadius: 4,
    color: colors.darkBlue,
    fontWeight: 500,
    "::placeholder": {
      color: colors.grey,
      fontWeight: 500,
    },
  },
  wrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderLeft: "1px solid #edeaea",
    cursor: "pointer",
  },
  inputWrapper: {
    position: "relative",
    height: 40,
    "@media (max-width: 600px)": {
      width: 308,
    },
    width: 358,
    marginLeft: 6,
    display: "flex",
    alignItems: "center",
  },
});

class Search extends React.Component<Props, State> {
  render() {
    const { onChange, searchValue } = this.props;

    return (
      <div className={css(styles.wrapper)}>
        <div className={css(styles.inputWrapper)}>
          <SearchIcon style={{ position: "absolute", marginLeft: 14 }} />
          <input
            className={css(styles.input)}
            value={searchValue}
            onChange={onChange}
            placeholder="Search site"
          />
        </div>
      </div>
    );
  }
}

export default Search;
