import { State } from "../reducers";

export const getAdditionals = (state: State) => state.additionals;

export const getAdditionalsForMonument = (state: State) => {
  const additionals = getAdditionals(state);

  return (monumentId: number) => {
    return Object.values(additionals).filter(
      (a) => a.monument_id === monumentId
    );
  };
};
