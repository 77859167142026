import { State } from "../reducers";
import { getUserEmail } from "./user";

export const getReviews = (state: State) => state.reviews;

export const getAllReviewsForCurrentUser = (state: State) => {
  const email = getUserEmail(state);
  const reviews = getReviews(state);

  return Object.values(reviews).filter(review => review.user_email === email);
};

export const getReviewForCurrentUser = (state: State, monumentId: number) => {
  const email = getUserEmail(state);
  const reviews = getReviews(state);

  return Object.values(reviews).find(
    review => review.user_email === email && review.monument_id === monumentId
  );
};
