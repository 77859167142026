import * as React from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { colors } from "../style";
import { Link } from "react-router-dom";

export interface FooterProps {}

export interface FooterState {}

const styles = StyleSheet.create({
  mainContainer: {
    display: "flex",
    padding: "40px 0px",
  },
  column: {
    flex: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  label: {
    color: colors.darkBlue,
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 14,
  },
  item: {
    margin: "8px 0px",
    color: colors.darkBlue,
    ":hover": {
      textDecoration: "underline",
    },
  },
  copyright: {
    alignItems: "flex-start",
    "@media (max-width: 600px)": {
      marginLeft: 20,
    },
  },
});

export default class Footer extends React.Component<FooterProps, FooterState> {
  state = {};

  public render() {
    return (
      <div className={css(styles.mainContainer)}>
        <div className={css(styles.column, styles.copyright)}>
          <div className={css(styles.label)}>© Monumap</div>
        </div>
        <div className={css(styles.column)}>
          <div className={css(styles.label)}>About us</div>
          <ul>
            <li className={css(styles.item)}>
              <a href="https://www.instagram.com/monumap/" target="_blank">
                Instagram
              </a>
            </li>
            <li className={css(styles.item)}>
              <a href="mailto:contact@monumap.com">Contact</a>
            </li>
          </ul>
        </div>
        <div className={css(styles.column)}>
          <div className={css(styles.label)}>Ressources</div>
          <ul>
            <li className={css(styles.item)}>
              <Link to="/privacy">Privacy policy</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
