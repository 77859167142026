import * as React from "react";
import { connect } from "react-redux";
import { getHighLights } from "../../actions/highlights";
import Homenav from "../../components/nav";
import { State } from "../../reducers";
import { getUserPermission, getUserAccessToken } from "../../selectors/user";
import { push } from "react-router-redux";
import { getHighlightsMonuments } from "../../selectors/highlights";
import { HighLight } from "../../reducers/highlights";
import { Monument, MonumentDict } from "../../reducers/monuments";
import { getMonuments } from "../../actions/monument";
import { postAllHighlights, deleteHighlight } from "../../actions/highlights";
import MonumentItem from "../../components/monumentItem";
import { getMonuments as getMonumentsSelector } from "../../selectors/monuments";
import { css, StyleSheet } from "aphrodite";
import { colors } from "../../style";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    "@media (max-width: 600px)": {
      flexDirection: "column-reverse",
    },
  },
  input: {
    width: "calc(100% - 80px)",
    margin: "40px 20px",
    padding: "20px",
    fontSize: 14,
  },
  highlightEdit: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  highlightContainer: {
    display: "flex",
    margin: "10px 20px",
  },
  addHighlight: {
    padding: "10px",
    backgroundColor: colors.mediumBlue,
    color: "white",
    borderRadius: 4,
    border: "none",
    cursor: "pointer",
    fontSize: 12,
    ":hover": {
      backgroundColor: colors.darkBlue,
    },
  },
  rankingInput: {
    padding: 10,
    fontSize: 12,
    marginBottom: 10,
  },
});

export interface ManageHighlightsProps {
  getHighLights: () => void;
  postAllHighlights: (
    monumentId: number,
    ranking: number,
    token: string
  ) => void;
  deleteHighlight: (monumentId: number, token: string) => void;
  userPermission?: string;
  push: typeof push;
  highlightsMonuments: {
    highlights: HighLight[];
    monuments: Monument[];
  };
  userToken: string;
  monuments: MonumentDict;
  getMonuments: () => void;
}

interface CompState {
  query: string;
  [id: number]: number;
}

class ManageHighlightsC extends React.Component<
  ManageHighlightsProps,
  CompState
> {
  state = {
    query: "",
  };

  componentWillMount() {
    this.props.getMonuments();
    this.props.getHighLights();
    if (this.props.userPermission !== "admin") {
      this.props.push("/");
    }
  }

  onAddHighlight = (monumentId: number) => {
    if (this.state[monumentId]) {
      this.props.postAllHighlights(
        monumentId,
        this.state[monumentId],
        this.props.userToken
      );
    }
  };

  onSetHighlightRank = (monumentId: number, value?: string) => {
    if (!value || isNaN(parseInt(value))) {
      this.setState({
        [monumentId]: 0,
      });
      return;
    }

    this.setState({
      [monumentId]: parseInt(value),
    });
  };

  onRemoveHighlight = (monumentId: number) => {
    this.props.deleteHighlight(monumentId, this.props.userToken);
  };

  onInputChange = (evt) => {
    if (evt.target) {
      this.setState({ query: evt.currentTarget.value });
    }
  };

  public render() {
    return (
      <div>
        <Homenav />
        <div className={css(styles.container)}>
          <div>
            {this.props.highlightsMonuments.monuments.map((m, i) => {
              const h = this.props.highlightsMonuments.highlights[i];
              return (
                <div key={i} style={{ display: "flex" }}>
                  {m && h && (
                    <MonumentItem
                      isAdmin={this.props.userPermission === "admin"}
                      onRemoveHighlight={this.onRemoveHighlight}
                      monument={m}
                      style={{ flex: 1 }}
                      highlight={h}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div style={{ flex: 1 }}>
            <input
              className={css(styles.input)}
              type="text"
              placeholder="Search for monument to highlight"
              onChange={this.onInputChange}
            />
            <div>
              {Object.values(this.props.monuments)
                .filter(
                  (m) =>
                    !!this.state.query &&
                    m.site_name
                      .toLowerCase()
                      .includes(this.state.query.toLowerCase())
                )
                .map((m, i) => (
                  <div key={i} className={css(styles.highlightContainer)}>
                    <MonumentItem monument={m} style={{ flex: 1 }} />
                    <div className={css(styles.highlightEdit)}>
                      <input
                        type="text"
                        placeholder="Ranking"
                        className={css(styles.rankingInput)}
                        value={this.state[m.monument_id] || 0}
                        onChange={(evt) =>
                          this.onSetHighlightRank(
                            m.monument_id,
                            evt.target.value
                          )
                        }
                      />
                      <button
                        className={css(styles.addHighlight)}
                        onClick={() => this.onAddHighlight(m.monument_id)}
                      >
                        Add highlight
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getHighLights,
  push,
  getMonuments,
  postAllHighlights,
  deleteHighlight,
};

const mapStateToProps = (state: State) => ({
  userPermission: getUserPermission(state),
  userToken: getUserAccessToken(state),
  highlightsMonuments: getHighlightsMonuments(state),
  monuments: getMonumentsSelector(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageHighlightsC);
