import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { MonumentDict, monuments } from "./monuments";
import { userReducer, UserState } from "./user";
import { ReviewState, reviewsReducer } from "./reviews";
import { RouterState } from "react-router-redux";
import { highLightsReducer, HighLightState } from "./highlights";
import { FilterState, filtersReducer } from "./filters";
import { additionalsReducer, AdditionalState } from "./additionals";

export interface State {
  monuments: MonumentDict;
  user: UserState;
  reviews: ReviewState;
  router: RouterState;
  highlights: HighLightState;
  filters: FilterState;
  additionals: AdditionalState;
}

const reducers = (history: any) =>
  combineReducers<State>({
    router: connectRouter(history),
    user: userReducer,
    reviews: reviewsReducer,
    highlights: highLightsReducer,
    monuments,
    filters: filtersReducer,
    additionals: additionalsReducer
  });

export default reducers;
