import { createSelector } from "reselect";
import { State } from "../reducers";

export const getFilters = (state: State) => state.filters;

export const getTabFilter = createSelector(
  getFilters,
  filters => filters.tab
);

export const getBoundsFilter = createSelector(
  getFilters,
  filters => filters.bounds
);

export const getQueryFilter = createSelector(
  getFilters,
  filters => filters.searchQuery
);

export const getSortFilter = createSelector(
  getFilters,
  filters => filters.sort
);
