import { SET_MONUMENTS, SET_PHOTOS } from "../constants/monument";
import { req, api } from "../fetch";
import { toast } from "react-toastify";

export const setMonuments = (payload: any) => ({
  type: SET_MONUMENTS,
  payload,
});

export const setPhotos = (payload: any[]) => ({
  type: SET_PHOTOS,
  payload,
});

export const getMonuments = () => (dispatch: any) =>
  fetch(req(api()))
    .then((res) => res.json())
    .then((payload) => dispatch(setMonuments(payload.data)));

export const fetchMonument = (ids: string) => (dispatch: any) =>
  Promise.all([
    fetch(req(api(`api-dev-monuments?monumentIds=${ids}`))).then((res) =>
      res.json()
    ),
    fetch(req(api(`api-dev-pictures?monumentIds=${ids}`))).then((res) =>
      res.json()
    ),
  ]).then(([monumentPayload, photoPayload]: any) => {
    if (monumentPayload.data) {
      dispatch(setMonuments(monumentPayload.data));
    }

    if (photoPayload.data) {
      dispatch(setPhotos(photoPayload.data));
    }
  });

export interface PostPicturesPayload {
  monument_id: number;
  picture_url: string;
  approved: number;
}

export const postPictures = (
  pictures: PostPicturesPayload[],
  token: string
) => () =>
  fetch(
    req(api("api-dev-pictures"), "POST", pictures, { authorization: token })
  )
    .then((res) => res.json())
    .then((photoPayload) => {
      toast.info(photoPayload.data, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    });
