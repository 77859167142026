import { Action } from "redux";
import { SET_HIGHLIGHTS } from "../constants/highlights";

export interface HighLight {
  id: string;
  monument_id: number;
  ranking_index: number;
  category: string;
  created_at: string;
  updated_at?: string;
}

export interface HighLightState {
  [key: string]: HighLight;
}

interface ActionWithPayload extends Action {
  payload: any;
}

const initialHighLightState = {};

export const highLightsReducer = (
  state: HighLightState = initialHighLightState,
  action: ActionWithPayload
) => {
  switch (action.type) {
    case SET_HIGHLIGHTS: {
      return {
        ...state,
        ...action.payload.reduce((acc, next) => {
          acc[next.id] = next;
          return acc;
        }, {})
      };
    }
    default: {
      return state;
    }
  }
};
