export const api = (endpoint = "api-dev-monuments") =>
  `https://6q9nfkbeb7.execute-api.eu-west-2.amazonaws.com/prod/${endpoint}`;

export const req = (url: string, method = "GET", body?: any, headers?: any) =>
  new Request(url, {
    method,
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Charset": "utf-8",
      ...headers,
    }),
    body: JSON.stringify(body),
  });
