import { req, api } from "../fetch";
import { SET_REVIEWS } from "../constants/review";
import { stringify } from "querystring";

export const setReviews = (payload: any[]) => ({
  type: SET_REVIEWS,
  payload
});

export interface GetReviewParams {
  monumentId?: number;
  userEmail?: string;
}

export const fetchReview = (params: GetReviewParams) => (dispatch: any) => {
  const paramsString = stringify(params);

  return fetch(req(api(`api-dev-get-reviews?${paramsString}`)))
    .then(res => res.json())
    .then(payload => {
      if (payload.data) {
        return dispatch(setReviews(payload.data));
      }
    });
};

export interface PostReviewPayload {
  monument_id: number;
  user_email: string;
  star?: boolean;
  rate?: number;
  comment?: string;
}

export const postReview = (payload: PostReviewPayload, token: string) => (
  dispatch: any
) => {
  const request = req(
    api("api-dev-post-review"),
    "POST",
    {
      ...payload,
      id: undefined
    },
    { Authorization: token }
  );

  return fetch(request)
    .then(res => res.json())
    .then(payload => dispatch(setReviews([payload.data])));
};
