// tslint:disable
import * as React from 'react';

export interface Props {
  size?: number;
  onClick: any;
}

export const Cross: React.StatelessComponent<Props> = ({ size = 15, onClick }) => (
  <svg width={size} height={size} viewBox="0 0 50 50" onClick={onClick} style={{ cursor: 'pointer' }}>
      <path fill="white" d="M1.436013,1.44001 C-0.478688,3.35461 -0.478688,6.45011 1.436013,8.36461 L18.074813,25.00212 L1.436013,41.63952 C-0.478688,43.55402 -0.478688,46.64952 1.436013,48.56412 C3.350813,50.47862 6.446412,50.47862 8.361113,48.56412 L25.000013,31.92672 L41.638812,48.56412 C43.553612,50.47862 46.649213,50.47862 48.563912,48.56412 C50.478712,46.64952 50.478712,43.55402 48.563912,41.63952 L31.925212,25.00212 L48.563912,8.36461 C50.478712,6.45011 50.478712,3.35461 48.563912,1.44001 C46.649213,-0.47449 43.553612,-0.47449 41.638812,1.44001 L25.000012,18.07751 L8.361112,1.44001 C7.403812,0.48281 6.153312,9.99999997e-06 4.898612,9.99999997e-06 C3.643813,1.00000001e-05 2.393413,0.48281 1.436013,1.44001 Z" id="Shape"/>
  </svg>
);
