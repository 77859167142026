import * as React from "react";
import { Monument } from "../reducers/monuments";
import { css, StyleSheet } from "aphrodite/no-important";
import { colors } from "../style";
import { Link } from "react-router-dom";

const styles = StyleSheet.create({
  container: {
    width: 400,
    backgroundColor: "white",
    cursor: "pointer",
    margin: 20,
    boxShadow: "1px 1px 5px 0px rgba(57,76,91,0.1)",
    ":hover": {
      boxShadow: "1px 1px 5px 0px rgba(57,76,91,0.4)",
    },
  },
  images: {
    width: "100%",
    height: 300,
    backgroundSize: "cover",
  },
  wrapper: {
    padding: 20,
  },
  states: {
    color: colors.grey,
    marginTop: 4,
  },
  name: {
    color: colors.darkBlue,
    fontSize: 20,
    lineHeight: 1.2,
    padding: "4px 0px",
  },
  description: {
    fontSize: 15,
    fontWeight: 300,
    color: colors.darkBlue,
    lineHeight: 1.4,
    marginTop: 20,
  },
});

export interface MonumentCardProps {
  monument: Monument;
  style?: React.CSSProperties;
  pictureIndex?: number;
}

export default class MonumentCardComp extends React.Component<
  MonumentCardProps
> {
  getTruncatedDescription = () => {
    const { monument } = this.props;
    const length = 150;

    return monument.short_description.length > length
      ? monument.short_description.substring(0, length) + "..."
      : monument.short_description;
  };

  getPictureUrl() {
    const { monument, pictureIndex } = this.props;

    if (monument.pictures && monument.pictures[pictureIndex]) {
      return monument.pictures[pictureIndex].picture_url;
    }

    if (monument.pictures && monument.pictures[0]) {
      return monument.pictures[0].picture_url;
    }

    return "/placeholder.png";
  }

  public render() {
    const { monument, style } = this.props;

    return (
      <div className={css(styles.container)} style={style}>
        <Link to={`/app/detail/${this.props.monument.monument_id}`}>
          <div
            className={css(styles.images)}
            style={{
              backgroundImage: `url("${this.getPictureUrl()}")`,
            }}
          />
          <div className={css(styles.wrapper)}>
            <div className={css(styles.name)}>{monument.site_name}</div>
            <div className={css(styles.states)}>{monument.states}</div>
            <div className={css(styles.description)}>
              {this.getTruncatedDescription()}
            </div>
          </div>
        </Link>
      </div>
    );
  }
}
