import { TabOption, SortOptions } from "../reducers/filters";
import { SET_TAB, SET_QUERY, SET_BOUNDS, SET_SORT } from "../constants/filters";

export const setTab = (tab: TabOption) => ({
  type: SET_TAB,
  payload: tab
});

export const setSort = (selectOption: keyof typeof SortOptions) => ({
  type: SET_SORT,
  payload: selectOption
});

export const setQuery = (query: string) => ({
  type: SET_QUERY,
  payload: query
});

export const setBounds = (bounds: number[]) => ({
  type: SET_BOUNDS,
  payload: bounds
});
