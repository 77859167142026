import * as React from "react";
import MapPopup from "./mapPopup";
import ReactMapboxGl from "react-mapbox-gl";
import { MapEvent } from "react-mapbox-gl/lib/map-events";
import MonumentLayer from "./monumentLayer";
import { MonumentLayout } from "./monumentLayer";
import { css, StyleSheet } from "aphrodite/no-important";
import { getViewportWidth } from "../utils";
import Navigation from "./navigation";
import { Monument } from "../reducers/monuments";
import { ReactComponent as Spinner } from "./spinner.svg";
import { connect } from "react-redux";
import { push } from "react-router-redux";

const accessToken =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_MONUMAP_DEV_TOKEN
    : process.env.REACT_APP_MONUMAP_WEB_PROD_TOKEN;

const Map = ReactMapboxGl({
  accessToken,
});

const mapStyle = process.env.REACT_APP_MONUMAP_STYLE_URL;

const styles = StyleSheet.create({
  map: {
    flex: 1,
    height: "calc(100vh - 80px)",
  },
  spinnerContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const defaultZoom: [number] = [6];

export interface Props {
  monuments: Monument[];
  BoundsChanged: MapEvent;
  mapInit: MapEvent;
  center: [number, number];
  zoom: [number];
  hoveredItem?: number;
  onMonumentClick: (k: number) => void;
  onMouseEnter: (key: number) => void;
  onMouseLeave: () => void;
  push: typeof push;
}

const cultureLayout: MonumentLayout = {
  "icon-image": "monument",
};

const natureLayout: MonumentLayout = {
  "icon-image": "nature",
};

class UnescoMap extends React.Component<Props> {
  private markerHover = (key: number, { map }: any) => {
    map.getCanvas().style.cursor = "pointer";
    this.props.onMouseEnter(key);
  };

  private markerEndHover = (key: string, { map }: any) => {
    map.getCanvas().style.cursor = "";
    this.props.onMouseLeave();
  };

  private onMapInit = (map: any, evt: any) => {
    // Hack to resize map when container is ready
    setTimeout(() => {
      map.resize();
    }, 0);

    this.props.mapInit(map, evt);
  };

  public render() {
    const {
      monuments,
      BoundsChanged,
      center,
      // zoom,
      hoveredItem,
      onMonumentClick,
    } = this.props;

    const cultural = monuments.filter(
      (monument) => monument.category !== "Natural"
    );

    const natural = monuments.filter(
      (monument) => monument.category === "Natural"
    );

    const vw = getViewportWidth();

    const HoveredMonument = monuments.find(
      (m) => m.monument_id === hoveredItem
    );

    return (
      <div className={css(styles.map)}>
        {vw < 600 && <Navigation view={false} />}
        {!monuments.length && (
          <div className={css(styles.spinnerContainer)}>
            <Spinner />
          </div>
        )}
        {
          <Map
            zoom={defaultZoom}
            center={center}
            style={mapStyle}
            containerStyle={{
              width: "100%",
              height: "100%",
              visibility: !!monuments.length ? "visible" : "hidden",
            }}
            onZoom={BoundsChanged}
            onStyleLoad={this.onMapInit}
            onMove={BoundsChanged}
          >
            {!!HoveredMonument ? (
              <MapPopup monument={HoveredMonument} />
            ) : undefined}
            <MonumentLayer
              onMonumentClick={onMonumentClick}
              monuments={cultural}
              layout={cultureLayout}
              markerHover={this.markerHover}
              markerEndHover={this.markerEndHover}
            />
            <MonumentLayer
              onMonumentClick={onMonumentClick}
              monuments={natural}
              layout={natureLayout}
              markerHover={this.markerHover}
              markerEndHover={this.markerEndHover}
            />
          </Map>
        }
      </div>
    );
  }
}

export default connect(null, { push })(UnescoMap);
