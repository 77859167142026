import { State } from "../reducers";
import { getAllReviewsForCurrentUser } from "./review";
import { getMonuments } from "./monuments";
import moment from "moment";

export const getFavouritesMonuments = (state: State) => {
  const reviews = getAllReviewsForCurrentUser(state);
  const monuments = getMonuments(state);

  return reviews
    .filter(review => !!review.star)
    .sort((ra, rb) => (moment(ra.created_at).isBefore(rb.created_at) ? -1 : 1))
    .map(review => monuments[review.monument_id]);
};
