import * as React from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite/no-important";
import { State as RootState } from "../reducers/index";
import MonumentItem from "./monumentItem";
import Navigation from "./navigation";
import { getFilteredMonuments } from "../selectors/monuments";
import { setQuery, setSort } from "../actions/filters";
import { SortOptions } from "../reducers/filters";
import { getHighlights } from "../selectors/highlights";
import { colors } from "../style";
import { getQueryFilter } from "../selectors/filters";
import { Monument } from "../reducers/monuments";

const styles = StyleSheet.create({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 80px)",
    width: 500,
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  list: {
    overflow: "auto",
    "-webkit-overflow-scrolling": "touch",
    maxHeight: "94vh",
  },
  seeMore: {
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "underline",
    cursor: "pointer",
    ":hover": {
      textDecoration: "none",
    },
  },
  sectionTitleWrapper: {
    padding: 16,
    display: "flex",
    alignItems: "center",
    backgroundColor: colors.darkBlue,
  },
  sectionTitle: {
    marginRight: 5,
    marginBottom: 0,
    fontSize: 14,
    cursor: "pointer",
    color: "white",
  },
});

export interface Ownprops {
  onMouseEnter: (key: number) => void;
  onMouseLeave: () => void;
  setQuery: typeof setQuery;
  setSort: typeof setSort;
}

export interface Props extends Ownprops {
  monuments: Monument[];
  searchValue: string;
}

interface CompState {}

class SidepanList extends React.Component<Props, CompState> {
  state = {};

  private onSearch = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.props.setQuery(target.value);
  };

  private onSelect = ({ target }: any) => {
    const sorkKey = Object.keys(SortOptions).find(
      (k) => SortOptions[k] === target.value
    ) as keyof typeof SortOptions;

    this.props.setSort(sorkKey);
  };

  public render() {
    const { monuments, onMouseEnter, onMouseLeave, searchValue } = this.props;

    return (
      <div className={css(styles.wrapper)}>
        <Navigation
          onSearch={this.onSearch}
          onSelect={this.onSelect}
          searchValue={searchValue}
          select={Object.values(SortOptions)}
          view={true}
        />
        <div className={css(styles.list)}>
          {monuments.map((monument, index) => (
            <MonumentItem
              key={index}
              monument={monument}
              onMouseEnter={() => onMouseEnter(monument.monument_id)}
              onMouseLeave={() => onMouseLeave()}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default connect(
  (state: RootState) => ({
    monuments: getFilteredMonuments(state),
    highlights: getHighlights(state),
    searchValue: getQueryFilter(state),
  }),
  { setQuery, setSort }
)(SidepanList);
