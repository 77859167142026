import * as React from "react";
import HomeNav from "../components/nav";
import { connect } from "react-redux";
import { css } from "aphrodite";
import { StyleSheet } from "aphrodite";
import { State } from "../reducers";
import { colors } from "../style";
import { Link } from "react-router-dom";
import HomeHighlight from "../components/home-highlight";
import Footer from "../components/footer";
import { ReactComponent as IconArrow } from "../components/arrow.svg";

const styles = StyleSheet.create({
  wrapper: {
    maxWidth: 1000,
    margin: "auto",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  infoWrapper: {
    maxWidth: 270,
    lineHeight: 2,
  },
  slider: {},
  info: {},
  allsitesButton: {
    cursor: "pointer",
    marginTop: 20,
    color: colors.orange,
    fontWeight: 600,
  },
  arrow: {
    height: 30,
    width: 30,
    borderRadius: "50%",
    border: "1px solid #ccc",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  topWrapper: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      margin: "0px 20px",
    },
  },
  mainInfoWrapper: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      margin: "0px 20px",
    },
  },
});

interface Props {}

class LandingComp extends React.Component<Props> {
  slider: any;

  private onPrev = () => {
    this.slider.slickPrev();
  };

  private onNext = () => {
    this.slider.slickNext();
  };

  render() {
    return (
      <div className={css(styles.wrapper)}>
        <HomeNav />
        <div className={css(styles.container)}>
          <div className={css(styles.mainInfoWrapper)}>
            <div className={css(styles.infoWrapper)}>
              <div>
                <div className={css(styles.info)}>
                  Book the best travels, inspired from 1121 sites classified
                  world heritage by the UNESCO.
                </div>
              </div>
              <Link to="/app" className={css(styles.allsitesButton)}>
                <span>View all sites</span>
                <IconArrow
                  style={{
                    transform: "rotate(-90deg)",
                    marginLeft: 10,
                    width: 10,
                  }}
                />
              </Link>
            </div>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <div className={css(styles.arrow)} onClick={this.onPrev}>
                <IconArrow
                  style={{
                    transform: "rotate(90deg)",
                  }}
                />
              </div>
              <div
                className={css(styles.arrow)}
                style={{ marginLeft: 14 }}
                onClick={this.onNext}
              >
                <IconArrow
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                />
              </div>
            </div>
          </div>

          <div className={css(styles.slider)}>
            <HomeHighlight onSliderRef={(ref) => (this.slider = ref)} />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({});

export const Landing = connect(mapStateToProps, {})(LandingComp);
