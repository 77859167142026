import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import logger from "redux-logger";
import thunk from "redux-thunk";

export const history = createBrowserHistory();

const store = createStore(
  rootReducer(history),
  applyMiddleware(thunk, routerMiddleware(history), logger)
);

export default store;
