import * as React from "react";
import { connect } from "react-redux";
import { State } from "../reducers/index";
import { getFavourites } from "../actions/favourites";
import { getUserEmail } from "../selectors/user";
import { getFavouritesMonuments } from "../selectors/favourites";
import { Monument } from "../reducers/monuments";
import HomeNav from "../components/nav";
import MonumentCard from "../components/monument-card";
import { css, StyleSheet } from "aphrodite/no-important";
import { colors } from "../style";
import { push } from "react-router-redux";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    minHeight: "100%",
  },
  grid: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: 40,
    "@media (max-width: 600px)": {
      padding: 0,
    },
  },
  title: {
    color: colors.darkBlue,
    fontSize: 30,
    lineHeight: 1.2,
  },
  wrapper: {
    margin: "0px 80px",
    marginTop: 40,
    padding: 20,
    borderBottom: `1px solid ${colors.border}`,
    "@media (max-width: 600px)": {
      margin: 20,
      padding: "20px 0px",
    },
  },
});

export interface FavouritesProps {
  getFavourites: (email: string) => any;
  userEmail: string;
  favouritesMonuments: Monument[];
  push: typeof push;
}

class FavouritesComp extends React.Component<FavouritesProps> {
  public componentWillMount() {
    const { userEmail } = this.props;
    if (userEmail) {
      this.props.getFavourites(userEmail);
    } else {
      this.props.push("/");
    }
  }

  public render() {
    const { favouritesMonuments } = this.props;
    return (
      <div className={css(styles.container)}>
        <HomeNav />
        <div className={css(styles.wrapper)}>
          <h2 className={css(styles.title)}>Your favourites sites</h2>
        </div>
        <div className={css(styles.grid)}>
          {favouritesMonuments.map((m) => (
            <MonumentCard monument={m} key={m.monument_id} />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  userEmail: getUserEmail(state),
  favouritesMonuments: getFavouritesMonuments(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getFavourites: (userEmail: string) => getFavourites(userEmail)(dispatch),
  push: (path: string) => dispatch(push(path)),
});

export const Favourites = connect(
  mapStateToProps,
  mapDispatchToProps
)(FavouritesComp);
