export const colors = {
  darkBlue: "#394c5b",
  mediumBlue: "#596a78",
  grey: "#a8aebf",
  lightGrey: "#f9f6f6",
  brokenWhite: "#f8f7f0",
  border: "#edeaea",
  yellow: "#fdcb6e",
  gyg: "#FF5533",
  orange: "#ED6142",
};
