import * as React from "react";
import { Monument } from "../reducers/monuments";
import { css, StyleSheet } from "aphrodite/no-important";
import { connect } from "react-redux";
import { State } from "../reducers";
import { getHighlightAndMonument } from "../actions/highlights";
import { getHighlightedMonuments } from "../selectors/highlights";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const styles = StyleSheet.create({
  container: {
    margin: "40px 0px",
  },
  slideContainer: {
    position: "relative",
    width: "100%",
    height: 560,
    backgroundSize: "cover",
  },
  wrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 40,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  descriptionContainer: {
    color: "white",
  },
  region: {
    textTransform: "uppercase",
    fontFamily: "Montserat, sans-serif",
    fontSize: 15,
    marginBottom: 14,
  },
  siteName: {
    color: "white",
    fontSize: 32,
    marginBottom: 16,
    lineHeight: 1.4,
    ":hover": {
      textDecoration: "underline",
    },
  },
  description: {
    lineHeight: 1.4,
    fontSize: 14,
    maxWidth: 450,
  },
  badge: {
    padding: "6px 10px",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    color: "white",
    display: "block",
  },
});

export interface HomeHighlightProps {
  monuments: Monument[];
  getHighlightAndMonument: () => void;
  onSliderRef: (ref: any) => any;
}

class HomeHighlight extends React.Component<HomeHighlightProps> {
  public componentWillMount() {
    this.props.getHighlightAndMonument();
  }

  getPictureUrl(monument: Monument) {
    if (monument.pictures && monument.pictures[0]) {
      return monument.pictures[0].picture_url;
    }

    return "/placeholder.png";
  }

  getTruncatedDescription = (monument: Monument) => {
    const length = 200;

    if (!monument.short_description) {
      return "";
    }

    return monument.short_description.length > length
      ? monument.short_description.substring(0, length) + "..."
      : monument.short_description;
  };

  public render() {
    const { monuments } = this.props;

    return (
      <div className={css(styles.container)}>
        {monuments.length > 0 && (
          <Slider dots infinite ref={(c: any) => this.props.onSliderRef(c)}>
            {monuments.map((monument) => (
              <Link
                key={monument.monument_id}
                to={`/app/detail/${monument.monument_id}`}
              >
                <div
                  key={monument.monument_id}
                  className={css(styles.slideContainer)}
                  style={{
                    backgroundImage: `url("${this.getPictureUrl(monument)}")`,
                  }}
                >
                  <div className={css(styles.wrapper)}>
                    <div style={{ display: "inline-flex" }}>
                      <div className={css(styles.badge)}>
                        Top summer destinations in Europe
                      </div>
                    </div>
                    <div className={css(styles.descriptionContainer)}>
                      <div className={css(styles.region)}>
                        {monument.region}
                      </div>
                      <h1 className={css(styles.siteName)}>
                        {monument.site_name}
                      </h1>

                      <p className={css(styles.description)}>
                        {this.getTruncatedDescription(monument)}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </Slider>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: State) => ({
  monuments: getHighlightedMonuments(state),
});

const mapDispatchToProps = {
  getHighlightAndMonument,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeHighlight);
