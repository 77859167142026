import * as React from "react";
import { css, StyleSheet } from "aphrodite/no-important";
import Logo from "../icons/logo";
import { Link } from "react-router-dom";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    display: "flex",
    height: 80,
    minHeight: 80,
    justifyContent: "space-between",
  },
  icon: {
    minWidth: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  title: {
    marginLeft: 10,
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
});

export interface NavContainerProps {}

export default class NavContainer extends React.Component<NavContainerProps> {
  public render() {
    const { children } = this.props;
    return (
      <div className={css(styles.container)}>
        <Link to="/" className={css(styles.icon)}>
          <Logo />
          <h1 className={css(styles.title)}>Monumap</h1>
        </Link>
        {children}
      </div>
    );
  }
}
