import { Action } from "redux";
import { SET_MONUMENTS, SET_PHOTOS } from "../constants/monument";

export interface Picture {
  picture_id: string;
  monument_id: number;
  picture_url: string;
  approved: number;
}

export interface Monument {
  monument_id: number;
  site_name: string;
  thumbnail: string;
  latitude: number;
  longitude: number;
  category: string;
  states: string;
  criteria: string;
  danger: string;
  inscribed_date: string;
  extension: number;
  historical_description: string;
  site_url: string;
  justification: string;
  location_description: string;
  long_descsription: string;
  region: string;
  revision: string;
  short_description: string;
  transboundary: number;
  unique_number: number;
  isoCode: string;
  secondary_dates: string;
  pictures?: Picture[];
  latlng: number[];
}

export interface MonumentDict {
  [id: string]: Monument;
}

export interface RThunkAction extends Action {
  payload: any;
  id?: string;
}

export const monuments = (
  state: MonumentDict = {},
  { type, payload, id }: RThunkAction
) => {
  switch (type) {
    case SET_MONUMENTS:
      return {
        ...payload
          .map((monument: Monument) => ({
            ...monument,
            latlng: [monument.longitude, monument.latitude],
          }))
          .reduce(
            (acc: MonumentDict, next: Monument) => {
              if (acc[next.monument_id]) {
                acc[next.monument_id] = { ...acc[next.monument_id], ...next };
              } else {
                acc[next.monument_id] = next;
              }

              return acc;
            },
            { ...state }
          ),
      };
    case SET_PHOTOS: {
      return {
        ...state,
        ...payload.reduce((acc, picture) => {
          const monument = state[picture.monument_id];

          if (acc[monument.monument_id]) {
            acc[monument.monument_id].pictures.push(picture);
          } else {
            acc[monument.monument_id] = {
              ...monument,
              pictures: [picture],
            };
          }

          return acc;
        }, {}),
      };
    }
    default:
      return state;
  }
};
