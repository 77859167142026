import { Action } from "redux";
import { SET_ADDITIONALS } from "../constants/additionals";

export interface Additional {
  id: string;
  monument_id: number;
  additional_informations?: string;
  ticket_url?: string;
  title?: string;
  rating?: string;
  affiliate_name?: string;
  created_at: string;
  updated_at?: string;
}

export interface AdditionalState {
  [key: string]: Additional;
}

interface ActionWithPayload extends Action {
  payload: any;
}

const initialAdditionalState = {};

export const additionalsReducer = (
  state: AdditionalState = initialAdditionalState,
  action: ActionWithPayload
) => {
  switch (action.type) {
    case SET_ADDITIONALS: {
      return {
        ...state,
        ...action.payload.reduce((acc, next) => {
          acc[next.id] = next;
          return acc;
        }, {}),
      };
    }
    default: {
      return state;
    }
  }
};
