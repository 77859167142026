import "react-toastify/dist/ReactToastify.css";
import "./common.css";
import "./mapbox.css";

import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Main from "./pages/app";
import Root from "./pages/root";
import store, { history } from "./store";
import { ConnectedRouter } from "connected-react-router";
import { Route } from "react-router";
import { Landing } from "./pages/landing";
import { setUserPosition } from "./actions/user";
import { Favourites } from "./pages/favourites";
import Privacy from "./pages/privacy";
import ManageHighlights from "./pages/admin/manageHighlights";
import ManageMonument from "./pages/admin/manage-monument";

navigator.geolocation.getCurrentPosition((pos) => {
  store.dispatch(setUserPosition(pos));
});

ReactDOM.render(
  <Provider store={store as any}>
    <ConnectedRouter history={history}>
      <Root>
        <Route path="/app" component={Main} />
        <Route exact path="/" component={Landing} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/favourites" component={Favourites} />
        <Route exact path="/manage-highlights" component={ManageHighlights} />
        <Route exact path="/manage-monument" component={ManageMonument} />
      </Root>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
