import * as React from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { colors } from "../style";
import { Monument } from "../reducers/monuments";
import { HighLight } from "../reducers/highlights";
import { connect } from "react-redux";
import { State } from "../reducers";
import { Additional } from "../reducers/additionals";
import { getAdditionalsForMonument } from "../selectors/additionals";
import { ReactComponent as GYGLogo } from "./gyg-logo.svg";
import { Link } from "react-router-dom";

export interface OwnProps {
  monument: Monument;
  onMouseEnter?: React.MouseEventHandler<HTMLElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLElement>;
  style?: React.CSSProperties;
  highlight?: HighLight;
  isAdmin?: boolean;
  onRemoveHighlight?: (monumentId: number) => void;
  onClick?: (evt: any) => void;
}

interface StateProps {
  additionals: Additional[];
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    margin: 8,
    padding: 8,
    borderRadius: 4,
    cursor: "pointer",
    ":hover": {
      backgroundColor: colors.lightGrey,
    },
  },
  flag: {
    flex: 1,
  },
  siteName: {
    ":hover": {
      textDecoration: "underline",
    },
  },
  description: {
    flex: 8,
  },
  second: {
    color: colors.grey,
    fontWeight: 300,
    marginTop: 6,
    lineHeight: 1.2,
    display: "flex",
    alignItems: "center",
  },
  state: {
    fontSize: 10,
    fontWeight: 400,
    textTransform: "uppercase",
    letterSpacing: "5 px",
    color: "#808492",
    marginRight: 4,
  },
  image: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: 80,
    height: 80,
    backgroundSize: "cover",
    borderRadius: 4,
    marginRight: 8,
  },
  highlightTitle: {
    marginRight: 6,
  },
  tourWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: 8,
    ":hover": {
      textDecoration: "underline",
    },
  },
});

const getTruncatedCountries = (countries: string) => {
  const length = 30;
  return countries.length > length
    ? countries.substring(0, length) + "..."
    : countries;
};

const MonumentItem: React.StatelessComponent<OwnProps & StateProps> = ({
  monument,
  onMouseEnter,
  onMouseLeave,
  style,
  highlight,
  isAdmin,
  onRemoveHighlight,
  additionals,
  onClick,
}) => (
  <div
    style={style}
    className={css(styles.container)}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={onClick}
  >
    <Link to={onClick ? "#" : `/app/detail/${monument.monument_id}`}>
      <div
        className={css(styles.image)}
        style={{ backgroundImage: `url("${monument.thumbnail}")` }}
      />
    </Link>
    <div className={css(styles.description)}>
      <Link to={onClick ? "#" : `/app/detail/${monument.monument_id}`}>
        <h1 className={css(styles.siteName)}>
          {!!highlight && (
            <span className={css(styles.highlightTitle)}>
              #{highlight.ranking_index}
            </span>
          )}
          {monument.site_name}
        </h1>
        <div className={css(styles.second)}>
          <p className={css(styles.state)}>
            {getTruncatedCountries(monument.states)}
          </p>
          <span> - {monument.inscribed_date}</span>
        </div>
        {isAdmin && !!highlight && (
          <button
            style={{ marginLeft: 10, cursor: "pointer" }}
            onClick={(evt) => {
              if (onRemoveHighlight) {
                evt.stopPropagation();
                evt.preventDefault();
                onRemoveHighlight(monument.monument_id);
              }
            }}
          >
            remove
          </button>
        )}
      </Link>
      <div>
        {!!additionals.length &&
          additionals.map(
            (a) =>
              a.ticket_url.includes("getyourguide") && (
                <a
                  key={a.ticket_url}
                  href={a.ticket_url}
                  target="_blank"
                  className={css(styles.tourWrapper)}
                >
                  <span
                    style={{
                      color: colors.gyg,
                      fontWeight: 500,
                      marginLeft: 4,
                      lineHeight: 1.2,
                    }}
                  >
                    GetYourGuide Tour
                  </span>
                </a>
              )
          )}
      </div>
    </div>
  </div>
);

const mapStateToProps = (state: State, ownProps: OwnProps) => ({
  additionals: getAdditionalsForMonument(state)(ownProps.monument.monument_id),
});

export default connect(mapStateToProps)(MonumentItem);
